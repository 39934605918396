
























import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class CmxNewsList extends Vue {
  contents: object[] = [];

  // 获取关于我们cms内容
  getContents () {
    var cond = {
      Key: 'MediaCoverage',
      Page: 1,
      PageSize: 3,
      SortOrder: 'DESC',
      SortName: 'CreateDate'
    };
    this.$Api.cms.getContentsByCatKeyEx(cond).then((result) => {
      this.contents = result.Data.slice(0, 3);
      result.Data.forEach(function (i) {
        var newDate = new Date(i.CreateDate.replace(/-/g, '/'));
        i.CreateDate = newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
      });
    });
  }
  showContent (val) {
    if (val.Url) {
      window.open(val.Url);
    } else {
      this.$router.push('/cms/content/' + val.Id);
    }
  }
  mounted () {
    this.getContents();
  }
}
